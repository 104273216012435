import React, { useEffect, useState } from 'react';
import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap';
import BarLoader from "react-spinners/BarLoader";
import toast from 'react-hot-toast'
import { useSubscriptionDataProvider } from '../../contexts';
function getColorWarning(count, limit) {
  const weight = (count) / limit;
  let dts = {};
  if (weight > 0.9) {
    dts['fontWeight'] = '700';
    dts['color'] = "#ff0000"
  } else if (weight > 0.7) {
    dts['fontWeight'] = '500';
    dts['color'] = "#ff8f00";
  } else {
    dts['fontWeight'] = '400';
    dts['color'] = "#000000"
  }

  return { ...dts }; // Return an object with the fontWeight property
}
const QueryRunner = (props) => {
  const [method, setMethod] = useState('POST');
  const [url, setUrl] = useState('');
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false)
  const [count,setCount] = useState(0)
  const [limit,setLimit] = useState(0)
  const {read,write} = useSubscriptionDataProvider()
  useEffect(()=>{
    if (read && read.usage) {
      setLimit(read.usage.limit)
      setCount(read.usage.count)
    }
  },[read])
  const handleRunQuery = async () => {
    setLoading(true)
    try {
      const res = await fetch("/api/v1/webQuery", {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "text": url,
        }),
      });
      const data = await res.json();
      setLoading(false)
      setResponse(data);
      if (read && read['usage'] && read['usage']['count'] < read['usage']['limit']) {
        write((prev)=>{
          prev['usage']['count'] = prev['usage']['count'] + 1
          return {...prev}
        })
      }

    } catch (error) {
      toast.error('Error running query:', error);
      setResponse(null);
      setLoading(false)
    }
  };

  const isJSON = (str) => {
    try {
      JSON.stringify(str);
      return true;
    } catch (error) {
      console.warn(error)
      return false;
    }
  };

  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <h2 style={{display: 'flex',justifyContent: 'space-between'}}>Query Runner <div className='queryCounter' style={{display: 'flex',
fontSize: '15px',
flexDirection: 'column',
alignItems: 'center',
fontWeight: '400'}}>
            <div>Queries used</div>
            <div style={getColorWarning(count,limit)}>{`${count}/${limit}`}</div></div></h2>
          <Form>
            <Form.Group controlId="methodSelect" style={{ display: "none" }}>
              <Form.Label>Select Method:</Form.Label>
              <Form.Control
                as="select"
                value={method}
                onChange={(e) => setMethod(e.target.value)}
              >
                <option value="GET">GET</option>
                <option value="POST">POST</option>
                <option value="OPTIONS">OPTIONS</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="urlInput">
              <Form.Label>Enter text:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter text"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              />
            </Form.Group>
            {!loading && (
              <Button variant="primary" onClick={handleRunQuery} className='mt-3'>
                Run
              </Button>
            )}
          </Form>
        </Col>
      </Row>
      {!loading && response && (
        <Row className="mt-3">
          <Col>
            <Card>
              <Card.Header>Response</Card.Header>
              <Card.Body>
                {isJSON(response) ? (
                  <pre>{JSON.stringify(response, null, 2)}</pre>
                ) : (
                  <p>{response}</p>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      {loading && <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop:"48px"
      }}>
        <BarLoader color="#0d6efd" />
      </div>}
    </Container>
  );
};

export default QueryRunner;
