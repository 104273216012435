import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import { Container, Navbar, Nav, Alert, Form,Modal,Button } from 'react-bootstrap';
import './App.css';
import { Outlet } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { HashLink } from 'react-router-hash-link';
import { useIsLoggedInProvider, useSubscriptionDataProvider } from './componet/contexts';
function App() {
    const {write} = useSubscriptionDataProvider()
    const navigate = useNavigate();
    const { loggedIn, login, logout } = useIsLoggedInProvider()
    const [showSignupModal, setShowSignupModal] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [isLoading,setIsLoading] = useState(false)
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        confirmPassword: '',
    });
    const [showAlert, setShowAlert] = useState(false);

    const handleCloseSignup = () => {
        setShowSignupModal(false);
    };

    const handleCloseLogin = () => {
        setShowLoginModal(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const eventListen = (ev) =>{
        if (ev.detail.button) {
            setShowSignupModal(true)
        }
    }
    useEffect(()=>{
        document.addEventListener('promptLogin',eventListen)
        return(()=>{
            document.removeEventListener("promptLogin",eventListen)
        })
    },[])
    const handleSignup = (e) => {
        e.preventDefault();
        if (isLoading) {
            return
        }
        setIsLoading(true)
        if (formData.password !== formData.confirmPassword) {
            toast.error("Passwords dont match",{duration:7500})
            return;
        }
        // Simulate sending verification code (in real scenario, this would be sent via email)
        // Close sign-up modal
        setShowAlert(false); // Hide password mismatch alert
        // Optionally: Perform backend request to complete signup process
        console.log('Signup Data:', formData);
        fetch("/api/v1/createAccount",{
            method:"POST",
            body:JSON.stringify({
                "email":formData.email,
                "password":formData.password,
                "passwordAgain":formData.confirmPassword
            }),
            headers:{
                "Content-Type": "application/json",
            }
        }).then((res)=>{
            if (res.ok) {
                setShowSignupModal(false);
                toast.success("Account created!",{duration:7500})
                toast.loading("Logging in to your account, Hold on!",{id:"Logging in to your account, Hold on!",duration:10000})
                setTimeout(() => {
                    fetch('/api/v1/getSubscription').then((res) => {
                        if (res.ok) {
                          res.json().then((res) => {
                            setIsLoading(false)
                            write(res)
                            login()
                            toast.dismiss("Logging in to your account, Hold on!")
                            toast.success("Logged in successfully",{duration:5000})
                            navigate('/account?tab=billing')
                          })
                        }else{
                            setIsLoading(false)
                        }
                      })
                }, 5000);
            }else{
                setIsLoading(false)
                if (res.status === 303) {
                    setShowSignupModal(false)
                    setShowLoginModal(true)
                    toast.error("It seems you already have an account, please login.",{duration:10000,id:"It seems you already have an account, please login."})
                    return
                }
                res.text().then((res)=>{
                    toast.error(res)
                    setFormData({
                        email: '',
                        password: '',
                        confirmPassword: '',
                    })
                })
            }
        })
        // Show verification code modal
        //setShowVerificationModal(true);
    };

    const handleLogin = (e) => {
        e.preventDefault();
        // Perform login logic (e.g., send credentials to backend)
        // convert event to form data object
        if (isLoading) {
            return
        }
        setIsLoading(true)
        const formData = new FormData(e.target);
        const formDataObj = Object.fromEntries(formData.entries());  
        //setShowLoginModal(false);
        toast.dismiss("It seems you already have an account, please login.")
        fetch("/api/v1/getAccount",{
            method:"POST",
            body:JSON.stringify({
                "email":formDataObj.email,
                "password":formDataObj.password,
            }),
            headers:{
                "Content-Type": "application/json",
            }
        }).then((res)=>{
            if (res.ok) {
                setShowLoginModal(false);
                login()
                //toast.success('Login succeeded');
                fetch('/api/v1/getSubscription').then((res) => {
                    if (res.ok) {
                      res.json().then((res) => {
                        write(res)
                        toast.success("Logged in successfully",{duration:5000})
                        navigate('/account')
                      })
                    }
                    setIsLoading(false)
                  })
            }else{
                setIsLoading(false)
                res.text().then((res)=>{
                    toast.error(res,{duration:5000})
                    setFormData({
                        email: '',
                        password: '',
                        confirmPassword: '',
                    })
                })
            }
        })
    }
    useEffect(() => {
        fetch('/api/v1/getSubscription').then((res) => {
          if (res.ok) {
            res.json().then((res) => {
              login()
              write(res)
            })
          }
        })
      }, [])
    return (
        <Container className="py-3">
            <header>
                <Navbar expand="md" className="d-flex flex-column flex-md-row align-items-center pb-3 mb-3 border-bottom">
                    <Navbar.Brand href="/" className="d-flex align-items-center text-dark text-decoration-none">
                        <span className="fs-4">Sally AI Text Classification</span>
                    </Navbar.Brand>
                    <Nav className="mt-2 mt-md-0 ms-md-auto">
                        <Link className='py-2 text-dark text-decoration-none nav-link' to={"/account?tab=docs"}>API docs</Link>
                        <Link className='py-2 text-dark text-decoration-none nav-link' to={"/account?tab=faq"}>FAQ</Link>
                        <HashLink to={'/shop#features'} className='py-2 text-dark text-decoration-none nav-link'>Features</HashLink>
                        <HashLink to={'/shop#pricing'} className='me-3 py-2 text-dark text-decoration-none nav-link'>Pricing</HashLink>
                        {!loggedIn &&  <Nav.Link className="py-2 text-primary text-decoration-none" onClick={()=>{
                            setShowLoginModal(true)
                        }}>Login</Nav.Link>}
                        {!loggedIn &&  <Nav.Link className="py-2 text-primary text-decoration-none" onClick={()=>{
                            setShowSignupModal(true)
                        }}>Sign up</Nav.Link>}
                        {loggedIn && <Link className="py-2 text-primary text-decoration-none" to='/account'>Account</Link>}
                        {loggedIn && <Nav.Link className="py-2 text-primary text-decoration-none" onClick={()=>{
                            logout()
                            navigate('/')
                            fetch('/api/logout')
                        }}>Logout</Nav.Link>}
                    </Nav>
                </Navbar>
            </header>
            <Outlet />
            {/* Sign Up Modal */}
            <Modal show={showSignupModal} onHide={handleCloseSignup}>
                <Modal.Header closeButton>
                    <Modal.Title>Sign Up</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSignup}>
                        <Form.Group controlId="email">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="confirmPassword">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Confirm Password"
                                name="confirmPassword"
                                value={formData.confirmPassword}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Col className="d-flex justify-content-between mt-3">
                            <Button variant="secondary" type='button' onClick={(e)=>{
                                setShowSignupModal(false)
                                setShowLoginModal(true)
                            }}>
                                Login
                            </Button>
                            <Button variant="primary" type="submit">
                                {!isLoading && "Sign Up"}
                                {isLoading && "Signing up"}
                            </Button>

                        </Col>
                    </Form>
                    {showAlert && (
                        <Alert variant="danger" className="mt-3">
                            Passwords do not match. Please try again.
                        </Alert>
                    )}
                </Modal.Body>
            </Modal>

            {/* Email Verification Modal */}
            <Modal show={showLoginModal} onHide={handleCloseLogin} onSubmit={handleLogin}>
                <Modal.Header closeButton>
                    <Modal.Title>Log In</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="email">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Col className="d-flex justify-content-between mt-3">
                            <Button variant="secondary" type='button' onClick={(e)=>{
                                setShowLoginModal(false)
                                setShowSignupModal(true)
                            }
                            }>
                                Sign Up
                            </Button>
                            <Button variant="primary" type="submit">
                                {!isLoading && "Log in"}
                                {isLoading && "Logging in"}
                            </Button>
                        </Col>
                    </Form>
                </Modal.Body>
            </Modal>
            {/* Log In Modal (To be implemented similarly) */}
            <footer className="pt-4 my-md-5 pt-md-5 border-top">
                <div className="col-12 col-md" style={{
                    display: 'flex',
                    gap: '8px',
                    alignItems: 'center',
                    justifyContent:" center"
                }}>
                    <a href='https://nrrinc.net' style={{textDecoration: 'none'}}><small className="d-block text-muted">Sally AI by NRRINC Media.</small></a><small style={{display: 'flex',gap: '6px',flexDirection: 'row',alignItems: 'center'}}><Link to={"/legal?tab=terms"} className='d-block text-muted'  style={{textDecoration: 'none'}}>Terms</Link> - <Link to='/legal?tab=privacy'  style={{textDecoration: 'none'}} className='d-block text-muted'>Privacy</Link></small>
                </div>
                {/* Additional footer columns */}
            </footer>
            <Toaster />
        </Container>
    );
}

export default App;
