import React, { useState,useEffect } from 'react';
import { Container, Tab, Nav,Tabs } from 'react-bootstrap';
import Terms from './terms'
import Privacy from './privacy'
import Refunds from './refund'
import { Link } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
const PolicyTabs = () => {
  const [activeTab, setActiveTab] = useState('terms');
  const [urlParam,setUrlParam]= useSearchParams()
  const handleTabSelect = (tab) => {
    setActiveTab(tab);
  };
  useEffect(()=>{
    if (urlParam.get('tab')) {
      if (urlParam.get('tab') === "terms") {
        setActiveTab("terms")
      }else if (urlParam.get('tab') === "privacy") {
        setActiveTab("privacy")
      }else if (urlParam.get('tab') === "refund") {
        setActiveTab("refund")
      }
    }
  },[urlParam])
  return (
    <Container className="mt-5">
      <h1>Policy Pages</h1>
      <Tabs
        id="user-account-tabs"
        activeKey={activeTab}
        onSelect={(k) => {handleTabSelect(k);
        if (k === "terms") {
          setUrlParam({"tab":"terms"})
        }else if (k === "privacy") {
          setUrlParam({"tab":"privacy"})
        }else if (k === "refund") {
          setUrlParam({"tab":"refund"})
        }
        }}
        className="mb-3"
      >
        <Tab eventKey="terms" title="Terms of Service">
          <h2>Terms of Service</h2>
          <Terms/>
          <Link to="/">Back to Main Site</Link>
        </Tab>
        <Tab eventKey="privacy" title="Privacy Policy">
          <h2>Privacy Policy</h2>
          <Privacy/>
          <Link to="/">Back to Main Site</Link>
        </Tab>
        <Tab eventKey="refund" title="Refund Policy">
          <h2>Refund Policy</h2>
          <Refunds/>
          <Link to="/">Back to Main Site</Link>
        </Tab>
      </Tabs>
    </Container>
  );
};

export default PolicyTabs;
