import { Card, Table } from 'react-bootstrap';
import './index.css'
import CheckIcon from '@mui/icons-material/Check';
import { useIsLoggedInProvider } from '../contexts';
import { useNavigate } from "react-router-dom";
const TableRow = ({ rowName, free = false, pro = false, enterprise = false,standard = false }) => {
    return (
        <tr>
            <th scope="row" className="text-start">{rowName}</th>
            <td>{typeof free === 'boolean' ? (free && <CheckIcon />) : free}</td>
            <td>{typeof standard === 'boolean' ? (standard && <CheckIcon />) : standard}</td>
            <td>{typeof pro === 'boolean' ? (pro && <CheckIcon />) : pro}</td>
            <td>{typeof enterprise === 'boolean' ? (enterprise && <CheckIcon />) : enterprise}</td>
        </tr>
    )
};
// Component for the check mark icon
const CardCol = ({ title, price, features, buttonText, buttonVariant }) => {
    const { loggedIn} = useIsLoggedInProvider()
    const navigate = useNavigate();
    return(
        <div className="col">
        <Card className="mb-4 rounded-3 shadow-sm">
            <Card.Header className="py-3">
                <h4 className="my-0 fw-normal">{title}</h4>
            </Card.Header>
            <Card.Body>
                <h1 className="card-title pricing-card-title">
                    {price}<small className="text-muted fw-light">/mo</small>
                </h1>
                <ul className="list-unstyled mt-3 mb-4">
                    {features.map((feature, index) => {
                        if (typeof feature === 'string') {
                            return <li key={index}>{feature}</li>;
                        } else {
                            return <li key={index}>&nbsp;</li>;
                        }
                    }
                    )}
                </ul>
                <button type="button" className={`w-100 btn btn-lg btn-${buttonVariant}`} onClick={()=>{
                    if (loggedIn) {
                        navigate('/account')
                    }else{
                        const myEvent = new CustomEvent('promptLogin', {
                            detail: {
                                button: title.toLowerCase()
                            }
                        });
                        document.dispatchEvent(myEvent);
                    }
                }}>
                    {buttonText}
                </button>
            </Card.Body>
        </Card>
    </div>
    )
}

export function StorePage(props) {
    return (
        <div>
            <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
                <h1 className="display-4 fw-normal">Pricing</h1>
                <p className="fs-5 text-muted">
                    Enhance text analysis with <i>Sally</i> for emotion analysis, keyword extraction, and tagging. Gain deeper insights into your text effortlessly.</p>
            </div>
            <main>
                <div className="row row-cols-1 row-cols-md-3 mb-3 text-center" id='pricing'>
                    {/* Pricing Cards */}
                    <CardCol title="Free" price="$0" features={['10 queries', 'API docs', true, true, true]} buttonText="Sign up for free" buttonVariant="outline-primary" />
                    <CardCol title="Standard" price="$9.99" features={['200 queries', 'HTTP API', 'API docs', true,true]} buttonText="Get started" buttonVariant="primary" />
                    <CardCol title="Pro" price="$99.99" features={['2500 queries', 'HTTP API', 'Email support', 'API docs', true]} buttonText="Get started" buttonVariant="primary" />
                    <CardCol title="Enterprise" price="$499.99" features={['150k queries', 'HTTP API', 'Priority email support', 'API docs', "Multi requests"]} buttonText="Get started" buttonVariant="primary" />
                </div>

                <h2 className="display-6 text-center mb-4" id='features'>Compare plans</h2>

                <div className="table-responsive">
                    <Table className="table text-center">
                        <thead>
                            <tr>
                                <th style={{ width: '30%' }}></th>
                                <th style={{ width: '17.5%' }}>Free</th>
                                <th style={{ width: '17.5%' }}>Standard</th>
                                <th style={{ width: '17.5%' }}>Pro</th>
                                <th style={{ width: '17.5%' }}>Enterprise</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* Table rows for plan comparison */}
                            <TableRow rowName="21 categories" free={true} standard={true} pro={true} enterprise={true} />
                            <TableRow rowName="21 categories with precentage" free={false} standard={true} pro={true} enterprise={true} />
                            <TableRow rowName="Keywords" free={false} standard={false} pro={true} enterprise={true} />
                            <TableRow rowName="Emtions and basetags" free={false} standard={false} pro={false} enterprise={true} />
                            <TableRow rowName="Web based API" free={true} standard={true} pro={true} enterprise={true} />
                            <TableRow rowName="HTTP API" free={"Web only"} standard={true} pro={true} enterprise={true} />
                            <TableRow rowName="Support" free={"No support"} standard={"No support"} pro={"Email, 72 hours TTR"} enterprise={"Email, 24 hours TTR"} />
                            <TableRow rowName="Auto updates" free={true} standard={true} pro={true} enterprise={true} />
                            <TableRow rowName="Multiuse API" free={false} standard={false} pro={false} enterprise={true} />
                            <TableRow rowName="Cost per query (CPQ)" free={"Free"} standard={"~$0.04995"} pro={"~$0.039996"} enterprise={"~$0.003333"} />
                        </tbody>
                    </Table>
                </div>
            </main>
        </div>
    );
};