import React, { useState } from 'react';
import { Container, Button, Modal, Form, Alert, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
    const [showSignupModal, setShowSignupModal] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        firstName: '',
        lastName: '',
        address: '',
        password: '',
        confirmPassword: '',
    });
    const [verificationCode, setVerificationCode] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [showVerificationModal, setShowVerificationModal] = useState(false);

    const handleShowSignup = () => {
        setShowSignupModal(true);
    };

    const handleCloseSignup = () => {
        setShowSignupModal(false);
    };

    const handleShowLogin = () => {
        setShowLoginModal(true);
    };

    const handleCloseLogin = () => {
        setShowLoginModal(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSignup = (e) => {
        e.preventDefault();
        if (formData.password !== formData.confirmPassword) {
            setShowAlert(true);
            return;
        }
        // Simulate sending verification code (in real scenario, this would be sent via email)
        setShowSignupModal(false); // Close sign-up modal// Reset email verification status
        setVerificationCode(''); // Reset verification code input
        setShowAlert(false); // Hide password mismatch alert
        // Optionally: Perform backend request to complete signup process
        // console.log('Signup Data:', formData);
        // Show verification code modal
        setShowVerificationModal(true);
    };

    const handleVerifyCode = (e) => {
        e.preventDefault();
        // Perform verification logic (e.g., compare entered code with expected value)
        if (verificationCode === '123456') {
            setShowVerificationModal(false); // Close verification modal
        } else {
            // Handle incorrect verification code scenario
            alert('Incorrect verification code. Please try again.');
        }
    };

    return (
        <Container className="mt-5">
            <Button variant="primary" onClick={handleShowSignup}>
                Sign Up
            </Button>
            <Button variant="secondary" onClick={handleShowLogin} className="ml-3">
                Log In
            </Button>
            {/* Sign Up Modal */}
            <Modal show={showSignupModal} onHide={handleCloseSignup}>
                <Modal.Header closeButton>
                    <Modal.Title>Sign Up</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSignup}>
                        <Form.Group controlId="email">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="firstName">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter first name"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="lastName">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter last name"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="confirmPassword">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Confirm Password"
                                name="confirmPassword"
                                value={formData.confirmPassword}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Col className="d-flex justify-content-between mt-3">
                            <Button variant="secondary" type='button' onClick={(e)=>{
                                setShowSignupModal(false)
                                setShowLoginModal(true)
                            }}>
                                Login
                            </Button>
                            <Button variant="primary" type="submit">
                                Sign Up
                            </Button>

                        </Col>
                    </Form>
                    {showAlert && (
                        <Alert variant="danger" className="mt-3">
                            Passwords do not match. Please try again.
                        </Alert>
                    )}
                </Modal.Body>
            </Modal>

            {/* Email Verification Modal */}
            <Modal show={showVerificationModal} onHide={() => setShowVerificationModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Enter Verification Code</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleVerifyCode}>
                        <Form.Group controlId="verificationCode">
                            <Form.Label>Verification Code</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter 6-digit code"
                                value={verificationCode}
                                onChange={(e) => setVerificationCode(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit" className='mt-3'>
                            Verify
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
            <Modal show={showLoginModal} onHide={handleCloseLogin}>
                <Modal.Header closeButton>
                    <Modal.Title>Log In</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="email">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Col className="d-flex justify-content-between mt-3">
                            <Button variant="secondary" type='button' onClick={(e)=>{
                                setShowLoginModal(false)
                                setShowSignupModal(true)
                            }
                            }>
                                Sign Up
                            </Button>
                            <Button variant="primary" type="submit">
                                Log In
                            </Button>
                        </Col>
                    </Form>
                </Modal.Body>
            </Modal>
            {/* Log In Modal (To be implemented similarly) */}
        </Container>
    );
}

export default App;
