import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Nav, Form, Button, Modal } from 'react-bootstrap';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { tomorrowNight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import toast from 'react-hot-toast';
import { useIsLoggedInProvider,useSubscriptionDataProvider } from '../../contexts';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import './index.css'
const MainDoc = () => {
    const { loggedIn, login, logout } = useIsLoggedInProvider()
    const [apiKey, setApiKey] = useState('');
    const [showKey, setShowKey] = useState(false);
    const [timer, setTimer] = useState(false);
    const timerRef = useRef(null);
    const hasConfirmedBefore = useRef(false)
    const [showKeyPromt, setShowKeyPromt] = useState(false);
    const {read,write} = useSubscriptionDataProvider()
    const [showAPIbar,setShowAPiBar] = useState(false)
    const toggleReaveal = () => {
        setShowKeyPromt(false)
        handleReveal()
    }
    const handleReveal = () => {
        setShowKey(!showKey)
        if (showKey === true) {
            return
        }
        hasConfirmedBefore.current = true
        if (timerRef.current) {
            return

        }
        timerRef.current = true
        setTimeout(() => {
            setTimer(9)
            setTimeout(() => {
                setTimer(8)
                setTimeout(() => {
                    setTimer(7)
                    setTimeout(() => {
                        setTimer(6)
                        setTimeout(() => {
                            setTimer(5)
                            setTimeout(() => {
                                setTimer(4)
                                setTimeout(() => {
                                    setTimer(3)
                                    setTimeout(() => {
                                        setTimer(2)
                                        setTimeout(() => {
                                            setTimer(1)
                                            setTimeout(() => {
                                                setTimer(false)
                                                setShowKey(false)
                                                timerRef.current = false
                                            }, 1000);
                                        }, 1000);
                                    }, 1000);
                                }, 1000);
                            }, 1000);
                        }, 1000);
                    }, 1000);
                }, 1000);
            }, 1000);
        }, 0);
    };

    const handleReset = () => {
        //const randomUUID = uuidv4();
        //setApiKey(randomUUID);
        fetch("/api/v1/apiKeyInfo").then((res)=>{
            if (res.ok) {
                res.json().then((res)=>{
                    if (res['status']) {
                        toast.error(res['error'])
                    }else{
                        write((prev)=>{
                            prev['user']['apiKey'] = res['key']
                            return {...prev}
                        })
                        toast.success('API key has been reset', { id: 'reset' });
                    }
                })
            }
        })
    };

    const handleInputChange = (e) => {
        setApiKey(e.target.value);
    };
    const copyToClipboard = async (e) =>{
        try {
            await navigator.clipboard.writeText(apiKey);
            toast.success("Copied API key")
          } catch (err) {
            console.error('Failed to copy:', err);
            toast.error('Failed to copy text to clipboard');
        }
    }
    useEffect(()=>{
        if (read){
            if  (read.currentPlan !== "free") {
                setShowAPiBar(true)
            }else{
                setShowAPiBar(false)
            }
        } 
        if (read && read.user && read.user.apiKey) {
            setApiKey(read.user.apiKey)
        }
    },[read])
    return (
        <Container>
            {loggedIn && showAPIbar && (
                <Row>
                    <Col>
                        <Form>
                            <Row className="align-items-center">
                                <Col style={{ display: 'flex', gap: '8px' }} className='docs-api-key-box'>
                                    <Form.Control
                                        type={showKey ? 'text' : 'password'}
                                        value={apiKey}
                                        onChange={handleInputChange}
                                        className='border border-3'
                                        style={{ width: '100%' }} // Make input full width
                                    />
                                    <div>
                                    <Button style={{display: 'flex',alignItems: 'center',gap: '8px'}} onClick={copyToClipboard}>
                                        <ContentCopyIcon/> Copy
                                    </Button>
                                    <Button variant="primary" className="mr-2 text-nowrap" onClick={() => {
                                        if (hasConfirmedBefore.current) {
                                            handleReveal()
                                        } else {
                                            setShowKeyPromt(true)
                                        }
                                    }}>
                                        {timer ? `(${timer}) Hide` : "Reveal"}
                                    </Button>
                                    <Button variant="danger" onClick={handleReset}>
                                        Reset
                                    </Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="align-items-center">
                                <Col>
                                    <Form.Text className="text-muted">
                                        Your API key is a unique identifier that allows you to access our API. Keep it safe and do not share it with others.
                                    </Form.Text>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            )}

            <Row style={{ marginTop: "32px" }}>

            </Row>
            <Row>
                <Col md={3}>
                    <Nav className="flex-column">
                        <Nav.Link href="#subdoc1">Quick start</Nav.Link>
                        <Nav.Link href="#subdoc2">API Error codes</Nav.Link>
                        <Nav.Link href="#subdoc3">JSON schema</Nav.Link>
                    </Nav>
                </Col>
                <Col md={9}>
                    <div id="subdoc1">
                        <h2>Quick start</h2>
                        <p>To get started, make sure you have Python installed on your machine.</p>

                        <h5>Step 1: Install Requests Library</h5>
                        <p>First, install the <code>requests</code> library if you haven't already:</p>
                        <SyntaxHighlighter language='bash' style={tomorrowNight}>
                            {`> pip install requests`}
                        </SyntaxHighlighter>
                        <h5>Step 2: Write Python Script</h5>
                        <p>Create a Python script (e.g., <code>api_request.py</code>) and use the following code:</p>
                        <SyntaxHighlighter language='python' style={tomorrowNight}>
                            {`import requests\nimport json\napi_key = 'YOUR_API_KEY_HERE'\nurl = 'https://tca-api.sally-ai.com?auth=' + api_key\n\ndata = json.dumps({"text":"Hello world"}) \nheaders = {"Content-Type": "application/json"}\n\nresponse = requests.get(url, data=data, headers=headers)\nif response.status_code == 200: \n     data = response.json()\n     print(data)\nelse:\n     print(f"Error: {response.status_code}")`}
                        </SyntaxHighlighter>

                        <h5>Step 3: Run the Script</h5>
                        <p>Save the script and run it using Python:</p>
                        <SyntaxHighlighter language='python' style={tomorrowNight}>
                            {`> python3 api_request.py`}
                        </SyntaxHighlighter>
                    </div>
                    <div id="subdoc2">
                        <h2>Error codes</h2>
                        <p>List of HTTP error codes that can occur</p>
                        <SyntaxHighlighter language='python' style={tomorrowNight}>
                            {`200: OK\n400: Bad Request (either text missing, or JSON not valid)\n401: Unauthorized (check API key)\n403: Forbidden (check payment method)\n429: Too Many Requests\n`}
                        </SyntaxHighlighter>
                        <h6>HTTP 429 does have rate limit headers</h6>
                        <SyntaxHighlighter language='python' style={tomorrowNight}>
                            {`RateLimit-Limit: 1000\nRateLimit-Remaining: 500\nRateLimit-Reset: 3600`}
                        </SyntaxHighlighter>
                    </div>
                    <div id="subdoc3">
                        <h2>JSON Schema</h2>
                        <SyntaxHighlighter language='json' style={tomorrowNight}>
                            {`{
  "data":dict {
    "baseTags":list [
      "Hello world"
    ],
    "emtions":list [
      dict:{
        "label":str "neutral",
        "score":float 0.9646915793418884
      },
      dict:{
        "label":str "approval",
        "score":float 0.012855284847319126
      }
    ],
    "keywords":str "Hello world,  world. Hello world.Hello world",
    "topics":list [
      dict:{
        "label":str "diaries_&_daily_life",
        "probability":float 0.8012698888778687
      }
    ]
  },
  "error":str|None null,
  "stauts":int 200
}`}
                        </SyntaxHighlighter>
                    </div>
                </Col>
            </Row>
            <Modal show={showKeyPromt} onHide={() => { setShowKeyPromt(false) }}>
                <Modal.Header>
                    <Modal.Title>API Key</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Your API key is a unique identifier that allows you to access our API. Keep it safe and do not share it with others.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setShowKeyPromt(false) }}>Close</Button>
                    <Button variant='primary' onClick={toggleReaveal}>Show</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default MainDoc;
