import React, { useEffect, useRef, useState } from 'react';
import { Form, Button, Alert, Modal, Col, Row, Card, FormCheck, FormLabel } from 'react-bootstrap';
import {
  PaymentElement,
  LinkAuthenticationElement,
} from '@stripe/react-stripe-js'
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import ProgressBar from 'react-bootstrap/ProgressBar';
import toast from 'react-hot-toast';
import { useSubscriptionDataProvider } from '../../contexts'
import visa from './cardIcons/visa.png'
import mastercard from './cardIcons/masterCard.png'
import discover from './cardIcons/discover.png'
import amex from './cardIcons/americanExpress.png'
import './index.css'
const typesOfSubs = {
  "default-radio-1": "free",
  "default-radio-2": "basic",
  "default-radio-3": "pro",
  "default-radio-4": "enterprise"
}
const typesOfSubsCost = {
  "default-radio-1": "Free",
  "default-radio-2": "$9.99",
  "default-radio-3": "$99.99",
  "default-radio-4": "$499.99"
}
const typesOfSubsBackwards = {
  "free": "default-radio-1",
  "basic": "default-radio-2",
  "pro": "default-radio-3",
  "enterprise": "default-radio-4",
  "standard": "default-radio-2"
}
function getColorWarning(count, limit) {
  const weight = (count) / limit;
  let dts = "";
  if (weight > 0.9) {
    dts = "bad";
  } else if (weight > 0.7) {
    dts = "warn";
  } else {
    dts = "safe"
  }

  return dts; // Return an object with the fontWeight property
}
const PaymentBlock = (props) => {
  const { read, write } = useSubscriptionDataProvider()
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    //const res = await fetch(`/update-subscription?type=${typesOfSubs[props.planChange]}`, {
    //  method: "POST",
    //});
    //let res;
    //if () {
    //  res = await fetch(`/addCard`, {
    //    method: "GET",
    //  });
    //}else{
    const res = await fetch(`/update-subscription?type=${typesOfSubs[props.planChange]}`, {
      method: "POST",
    });
    //}

    const { type, clientSecret } = await res.json();
    const confirmIntent = type === "setup" ? stripe.confirmSetup : stripe.confirmPayment;
    await elements.submit()
    // Confirm the Intent using the details collected by the Payment Element
    const { error, paymentIntent } = await confirmIntent({
      elements,
      clientSecret,
      confirmParams: {
        return_url: "http://localhost:3006/account"
      },
      return_url: "http://localhost:3006/account",
      redirect: 'if_required'
    });
    if (error === undefined && paymentIntent === undefined) {
      toast.loading("Hang on! We are proccesing your new card", { duration: 10000, id: "We are proccesing your payment" })
      setTimeout(() => {
        fetch('/api/v1/getSubscription').then((res) => {
          if (res.ok) {
            res.json().then((res) => {
              write(res)
              console.log("Card updated");
              props.setShowModal(false)
              setIsLoading(false);
              toast.remove("We are proccesing your payment")
              toast.success('Payment method updated!',{duration:7500});
            })
          }
        })
      }, 10000);
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      toast.loading("Hang on! We are proccesing your payment", { duration: 10000, id: "We are proccesing your payment" })
      setTimeout(() => {
        fetch('/api/v1/getSubscription').then((res) => {
          if (res.ok) {
            res.json().then((res) => {
              write(res)
              console.log("Payment succeeded");
              props.setShowModal(false)
              setIsLoading(false);
              toast.remove("We are proccesing your payment")
              toast.success('Payment succeeded',{duration:7500});
            })
          }
        })
      }, 10000);
      // handleSuccess();
    } else if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
      setIsLoading(false);
    } else {
      setMessage("An unexpected error occured.");
      setIsLoading(false);
    }
    //// This point will only be reached if there is an immediate error when
    //// confirming the payment. Otherwise, your customer will be redirected to
    //// your `return_url`. For some payment methods like iDEAL, your customer will
    //// be redirected to an intermediate site first to authorize the payment, then
    //// redirected to the `return_url`.
    //if (paymentIntent && paymentIntent.status === "succeeded") {
    //  console.log("Payment succeeded");
    //  props.setShowModal(false)
    //  toast.success('Payment succeeded');
    //  // handleSuccess();
    //} else if (error.type === "card_error" || error.type === "validation_error") {
    //  setMessage(error.message);
    //} else  {
    //  setMessage("An unexpected error occured.");
    //}
  }
  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <LinkAuthenticationElement id="link-authentication-element"
      // Access the email value like so:
      // onChange={(event) => {
      //  setEmail(event.value.email);
      // }}
      //
      // Prefill the email field like so:
      // options={{defaultValues: {email: 'foo@bar.com'}}}
      />
      <PaymentElement id="payment-element" />
      <Row className=' mt-3'>
        <Col>
          <Button variant="danger" onClick={handleSubmit}>
            Cancel
          </Button>

        </Col>
        <Col className='d-flex justify-content-end'>
          <Button disabled={isLoading || !stripe || !elements} id="submit" type='submit'>
            <span id="button-text">
              {isLoading ? <div className="spinner" id="spinner"></div> : "Save"}
            </span>
          </Button>
        </Col >
      </Row>

      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  )
}
const UpdateBlock = (props) => {
  const { read, write } = useSubscriptionDataProvider()
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements || isLoading) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    //const res = await fetch(`/update-subscription?type=${typesOfSubs[props.planChange]}`, {
    //  method: "POST",
    //});
    //let res;
    //if () {
    const res = await fetch(`/addCard`, {
      method: "GET",
    });
    //}else{
    //const res = await fetch(`/update-subscription?type=${typesOfSubs[props.planChange]}`, {
    //  method: "POST",
    //});
    //}

    const { type, clientSecret } = await res.json();
    const confirmIntent = type === "setup" ? stripe.confirmSetup : stripe.confirmPayment;
    await elements.submit()
    // Confirm the Intent using the details collected by the Payment Element
    const { error, paymentIntent } = await confirmIntent({
      elements,
      clientSecret,
      confirmParams: {
        return_url: "http://localhost:3006/account"
      },
      return_url: "http://localhost:3006/account",
      redirect: 'if_required'
    });
    if (error === undefined && paymentIntent === undefined) {
      toast.loading("Hang on! We are proccesing your new card", { duration: 10000, id: "We are proccesing your payment" })
      setTimeout(() => {
        fetch('/api/v1/getSubscription').then((res) => {
          if (res.ok) {
            res.json().then((res) => {
              write(res)
              console.log("Card updated");
              props.setShowModal(false)
              setIsLoading(false);
              toast.remove("We are proccesing your payment")
              toast.success('Payment method updated!',{duration:7500});
            })
          }
        })
      }, 10000);
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      toast.loading("Hang on! We are proccesing your payment", { duration: 10000, id: "We are proccesing your payment" })
      setTimeout(() => {
        fetch('/api/v1/getSubscription').then((res) => {
          if (res.ok) {
            res.json().then((res) => {
              write(res)
              console.log("Payment succeeded");
              props.setShowModal(false)
              setIsLoading(false);
              toast.remove("We are proccesing your payment")
              toast.success('Payment succeeded',{duration:7500});
            })
          }
        })
      }, 10000);
      // handleSuccess();
    } else if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
      setIsLoading(false);
    } else {
      setMessage("An unexpected error occured.");
      setIsLoading(false);
    }
  }
  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <LinkAuthenticationElement id="link-authentication-element"
      // Access the email value like so:
      // onChange={(event) => {
      //  setEmail(event.value.email);
      // }}
      //
      // Prefill the email field like so:
      // options={{defaultValues: {email: 'foo@bar.com'}}}
      />
      <PaymentElement id="payment-element" />
      <Row className=' mt-3'>
        <Col>
          <Button variant="danger" onClick={handleSubmit}>
            Cancel
          </Button>

        </Col>
        <Col className='d-flex justify-content-end'>
          <Button disabled={isLoading || !stripe || !elements} id="submit" type='submit'>
            <span id="button-text">
              {isLoading ? <div className="spinner" id="spinner"></div> : "Save"}
            </span>
          </Button>
        </Col >
      </Row>

      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  )
}
const CreditCardForm = (props) => {
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [stripePromise, setStripePromise] = useState(false);
  const [clientSecret, setClientSecret] = useState(false);
  useEffect(() => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      setStripePromise(loadStripe("pk_test_51IoFqHGZeLdJNBfCGFrInUXUuvxJNl3uO1q72s5ls4OBUETV8pwD87eheXct6mt2b9V5xjbU9zh8BoyYgW6NkOJ200mdPlgzi9"))
    } else {
      setStripePromise(loadStripe("pk_live_51IoFqHGZeLdJNBfCpX2sIWt3FDUz7fw7kySKiW3k6AR57f5hVCW8jKd8JSSC7yS9hOV1m6FaAACxv3klvQOgHAUh00cNIRTtCx"))
    }

  }, []);
  const options = {
    mode: 'subscription',
    amount: 0,
    currency: 'usd',
    // Fully customizable with appearance API.
    appearance: {/*...*/ },
  };
  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch("/create-payment-intent2")
      .then((res) => res.json())
      .then(({ clientSecret }) => setClientSecret(clientSecret));
  }, []);
  return clientSecret && stripePromise && (
    <div>
      <Elements stripe={stripePromise} options={options}>
        <PaymentBlock setShowModal={props.setShowModal} planChange={props.planChange} />
      </Elements>
    </div>
  )
};
const UpdateCreditCardForm = (props) => {
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [stripePromise, setStripePromise] = useState(false);
  const [clientSecret, setClientSecret] = useState(false);
  useEffect(() => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      setStripePromise(loadStripe("pk_test_51IoFqHGZeLdJNBfCGFrInUXUuvxJNl3uO1q72s5ls4OBUETV8pwD87eheXct6mt2b9V5xjbU9zh8BoyYgW6NkOJ200mdPlgzi9"))
    } else {
      setStripePromise(loadStripe("pk_live_51IoFqHGZeLdJNBfCpX2sIWt3FDUz7fw7kySKiW3k6AR57f5hVCW8jKd8JSSC7yS9hOV1m6FaAACxv3klvQOgHAUh00cNIRTtCx"))
    }
  }, []);
  const options = {
    mode: 'subscription',
    amount: 0,
    currency: 'usd',
    // Fully customizable with appearance API.
    appearance: {/*...*/ },
  };
  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch("/create-payment-intent2")
      .then((res) => res.json())
      .then(({ clientSecret }) => setClientSecret(clientSecret));
  }, []);
  return clientSecret && stripePromise && (
    <div>
      <Elements stripe={stripePromise} options={options}>
        <UpdateBlock setShowModal={props.setShowModal} planChange={props.planChange} />
      </Elements>
    </div>
  )
};
const ChangePlan = (props) => {
  const { read, write } = useSubscriptionDataProvider()
  const [selectedCard, setSelectedCard] = useState(null);
  const [price,setPrice] = useState(false)
  // currnetPlan={currnetPlan} setCurrentPlan={setCurrentPlan}
  useEffect(() => {
    setSelectedCard(props.currnetPlan)
  }, [props])
  const handleCardClick = (cardId) => {
    const a = cardId === selectedCard ? null : cardId
    setSelectedCard(cardId === selectedCard ? null : cardId);
    if (typesOfSubsBackwards[props.paymentInfo['currentPlan']] === a) {
      setPrice(false)
    }else{
      setPrice(typesOfSubsCost[a])
    }    
  };

  const handleSave = () => {
    // Perform save action here
    //props.setChangePlanModal(false);
    //props.setCurrentPlan(selectedCard)
    if (typesOfSubsBackwards[props.paymentInfo['currentPlan']] === selectedCard) {
      toast.error('You already have this plan activated',{duration:7500});
    } else {
      props.newPlanRef.current = {
        "type":typesOfSubs[selectedCard]
      }
      props.setChangePlanModal(false)
      props.setNewPriceModal(true)
      return
      const i = toast.loading("Proccessing plan", { duration: 10000 })
      fetch(`/update-subscription?type=${typesOfSubs[selectedCard]}`).then((res) => {
        if (res.ok) {
          fetch('/api/v1/getSubscription').then((res) => {
            if (res.ok) {
              res.json().then((res) => {
                write(res)
                toast.remove(i)
                toast.success('Plan changed started, Please allow upto 24 hours for changes to take affect', { duration: 10000 });
                props.setChangePlanModal(false);
              })
            }
          })
        }
      })
    }
  };
  return (
    <div>
      <Modal.Body>
        <div className="card-container">
          <div
            className={`custom-card ${selectedCard === 'default-radio-1' ? 'selected' : ''}`}
            onClick={() => handleCardClick('default-radio-1')}
          >
            <Card.Body>
              <Card.Title>Free</Card.Title>
              <Card.Text>
                10 queries
                API docs
              </Card.Text>
              <Button variant="primary">{selectedCard === 'default-radio-1' ? 'Selected' : 'Select'}</Button>
            </Card.Body>
          </div>

          <div
            className={`custom-card ${selectedCard === 'default-radio-2' ? 'selected' : ''}`}
            onClick={() => handleCardClick('default-radio-2')}
          >
            <Card.Body>
              <Card.Title>Standard</Card.Title>
              <Card.Text style={{ fontWeight: 700, fontSize: "1.25rem", marginBottom: "0" }}>$9.99/mo</Card.Text>
              <Card.Text>
                200 queries
                HTTP API
                API docs
              </Card.Text>
              <Button variant="primary">{selectedCard === 'default-radio-2' ? 'Selected' : 'Select'}</Button>
            </Card.Body>
          </div>

          <div
            className={`custom-card ${selectedCard === 'default-radio-3' ? 'selected' : ''}`}
            onClick={() => handleCardClick('default-radio-3')}
          >
            <Card.Body>
              <Card.Title>Pro</Card.Title>
              <Card.Text style={{ fontWeight: 700, fontSize: "1.25rem", marginBottom: "0" }}>$99.99/mo</Card.Text>
              <Card.Text>
                2500 queries
                HTTP API
                Email support
                API docs
              </Card.Text>
              <Button variant="primary">{selectedCard === 'default-radio-3' ? 'Selected' : 'Select'}</Button>
            </Card.Body>
          </div>
          <div
            className={`custom-card ${selectedCard === 'default-radio-4' ? 'selected' : ''}`}
            onClick={() => handleCardClick('default-radio-4')}
          >
            <Card.Body>
              <Card.Title>Enterprise</Card.Title>
              <Card.Text style={{ fontWeight: 700, fontSize: "1.25rem", marginBottom: "0" }}>$499.99/mo</Card.Text>
              <Card.Text>
                150k queries
                HTTP API
                Priority email support
                API docs
                Multi requests
              </Card.Text>
              <Button variant="primary">{selectedCard === 'default-radio-4' ? 'Selected' : 'Select'}</Button>
            </Card.Body>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.setChangePlanModal(false)}>
          Cancel
        </Button>
        {selectedCard && (
          <Button variant="primary" onClick={handleSave}>
            {price&& ("Update plan")}
            {!price&& ("Save")}
          </Button>
        )}
      </Modal.Footer>
    </div>
  )
}

const InitChangePlan = (props) => {
  const [selectedCard, setSelectedCard] = useState(null);
  // currnetPlan={currnetPlan} setCurrentPlan={setCurrentPlan}
  useEffect(() => {
    setSelectedCard(props.currnetPlan)
  }, [props])
  const handleCardClick = (cardId) => {
    setSelectedCard(cardId === selectedCard ? null : cardId);
  };

  const handleSave = () => {
    // Perform save action here
    props.setChangePlanModal(false);
    props.setCurrentPlan(selectedCard)
    //toast.success('Plan changed successfully');
    props.openCard(true)
  };
  return (
    <div>
      <Modal.Body>
        <div className="card-container">
          <div
            className={`custom-card ${selectedCard === 'default-radio-1' ? 'selected' : ''}`}
            onClick={() => handleCardClick('default-radio-1')}
          >
            <Card.Body>
              <Card.Title>Free</Card.Title>
              <Card.Text>
                10 queries
                API docs
              </Card.Text>
              <Button variant="primary">{selectedCard === 'default-radio-1' ? 'Selected' : 'Select'}</Button>
            </Card.Body>
          </div>

          <div
            className={`custom-card ${selectedCard === 'default-radio-2' ? 'selected' : ''}`}
            onClick={() => handleCardClick('default-radio-2')}
          >
            <Card.Body>
              <Card.Title>Standard</Card.Title>
              <Card.Text style={{ fontWeight: 700, fontSize: "1.25rem", marginBottom: "0" }}>$9.99/mo</Card.Text>
              <Card.Text>
                200 queries
                HTTP API
                API docs
              </Card.Text>
              <Button variant="primary">{selectedCard === 'default-radio-2' ? 'Selected' : 'Select'}</Button>
            </Card.Body>
          </div>

          <div
            className={`custom-card ${selectedCard === 'default-radio-3' ? 'selected' : ''}`}
            onClick={() => handleCardClick('default-radio-3')}
          >
            <Card.Body>
              <Card.Title>Pro</Card.Title>
              <Card.Text style={{ fontWeight: 700, fontSize: "1.25rem", marginBottom: "0" }}>$99.99/mo</Card.Text>
              <Card.Text>
                2500 queries
                HTTP API
                Email support
                API docs
              </Card.Text>
              <Button variant="primary">{selectedCard === 'default-radio-3' ? 'Selected' : 'Select'}</Button>
            </Card.Body>
          </div>
          <div
            className={`custom-card ${selectedCard === 'default-radio-4' ? 'selected' : ''}`}
            onClick={() => handleCardClick('default-radio-4')}
          >
            <Card.Body>
              <Card.Title>Enterprise</Card.Title>
              <Card.Text style={{ fontWeight: 700, fontSize: "1.25rem", marginBottom: "0" }}>$499.99/mo</Card.Text>
              <Card.Text>
                150k queries
                HTTP API
                Priority email support
                API docs
                Multi requests
              </Card.Text>
              <Button variant="primary">{selectedCard === 'default-radio-4' ? 'Selected' : 'Select'}</Button>
            </Card.Body>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.setChangePlanModal(false)}>
          Cancel
        </Button>
        {selectedCard === 'default-radio-1' ? (
          <Button variant="primary" onClick={() => props.setChangePlanModal(false)}>
            Save
          </Button>
        ) : (
          <Button variant="primary" onClick={handleSave}>
            Next
          </Button>
        )}

      </Modal.Footer>
    </div>
  )
}

const ChangePassword = (props) => {
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordAgain, setNewPasswordAgain] = useState('');
  const [loading,setIsLoading] = useState(false)
  const test = () =>{
    if (loading) {
      return
    }
    setIsLoading(true)
    fetch('/api/changePassword',{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "oldPassword":password,
        "newPassword":newPassword,
        "newPasswordAgain":newPasswordAgain
      })
    }).then((res)=>{
      if (res.ok) {
        res.text().then((res2)=>{
          toast.success(res2,{duration:5000})
          props.setChangePasswordModal(false)
        })
      }else{
        res.text().then((res2)=>{
          setIsLoading(false)
          toast.error(res2,{duration:5000})
        })
      }
    })
  }
  return (
    <div>
      <Modal.Body>
        <Form style={{ display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'flex-start' }}>
          <Form.Group controlId="password" style={{ width: '100%' }}>
            <Form.Label>Old password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Form.Label>New password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter new password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <Form.Label>New password (again)</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter new password"
              value={newPasswordAgain}
              onChange={(e) => setNewPasswordAgain(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "space-between" }}>
        <Button variant="secondary" onClick={() => props.setChangePasswordModal(false)}>
          Cancel
        </Button>
        <Button variant="primary" onClick={test}>
          {!loading && ("Save")}
          {loading && ("Saving")}
        </Button>
      </Modal.Footer>
    </div>
  )
}

const ChangeEmail = (props) => {
  const [newEmail, setNewEmail] = useState('');
  const [password,setPassword] = useState('')
  const [loading,setIsLoading] = useState(false)
  const test = () =>{
    if (loading) {
      return
    }
    setIsLoading(true)
    fetch('/api/changeEmail',{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "password":password,
        "email":newEmail
      })
    }).then((res)=>{
      if (res.ok) {
        res.text().then((res2)=>{
          toast.success(res2,{duration:5000})
          props.setChangeEmailModal(false)
        })
      }else{
        res.text().then((res2)=>{
          setIsLoading(false)
          toast.error(res2,{duration:5000})
        })
      }
    })
  }
  return (
    <div>
      <Modal.Body>
        <Form style={{ display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'flex-start' }}>
          <Form.Group controlId="email" style={{ width: '100%' }}>
            <Form.Label>New email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter new email"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
            />
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "space-between" }}>
        <Button variant="secondary" onClick={() => props.setChangeEmailModal(false)}>
          Cancel
        </Button>
        <Button variant="primary" onClick={test}>
        {!loading && ("Save")}
          {loading && ("Saving")}
        </Button>
      </Modal.Footer>
    </div>
  )
}

const BillingSelfInfo = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [confirmRemoveModalLoading, setConfirmRemoveModalLoading] = useState(false);
  const [confirmRemoveModal, setConfirmRemoveModal] = useState(false);
  const [changePlanModal, setChangePlanModal] = useState(false);
  const [changeEmailModal, setChangeEmailModal] = useState(false);
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [error, setError] = useState(null);
  const [currnetPlan, setCurrentPlan] = useState('default-radio-1');
  const [paymentInfo, setPaymentInfo] = useState(false)
  const [changePaymenthMethod,setChangePaymentMethod] = useState(false)
  const [newPlan,setNewPlan] = useState(false)
  const newPlanRef = useRef(false)
  const { read, write } = useSubscriptionDataProvider()
  const [initChangePlanModal, setInitChangePlanModal] = useState(false)
  const [newPriceModal,setNewPriceModal] = useState(false)
  const [newPriceLoading,setNewPriceModalLoading] = useState(false)
  const handleSaveNewPlan = () => {
    // Perform save action here
    //props.setChangePlanModal(false);
    //props.setCurrentPlan(selectedCard)
    setNewPriceModalLoading(true)
      const i = toast.loading("Proccessing plan", { duration: 10000 })
      fetch(`/update-subscription?type=${newPlanRef.current.type}`).then((res) => {
        if (res.ok) {
          fetch('/api/v1/getSubscription').then((res) => {
            if (res.ok) {
              res.json().then((res) => {
                write(res)
                toast.remove(i)
                toast.success('Plan changed started, Please allow upto 24 hours for changes to take affect', { duration: 10000 });
                setNewPriceModal(false);
              })
            }
          })
        }else{
          setNewPriceModalLoading(false)
        }
      })

  };
  const handleAddPaymentMethod = () => {
    setShowModal(true);
  };
  const handleChangePaymentMethod = () => {
    setChangePaymentMethod(true);
  };
  const [delCard, setDelCard] = useState(false)


  const getCardIcon = (brand) => {
    switch (brand) {
      case 'visa':
        return visa;
      case 'mastercard':
        return mastercard;
      case "discover":
        return discover;
      case "amex":
        return amex;
    }
  };

  const handleRemovePaymentMethod = (paymentMethod) => {
    setConfirmRemoveModal(true);
  };

  const confirmRemove = () => {
    // Implement logic to remove selected payment method from the list
    setConfirmRemoveModal(false);
    setConfirmRemoveModalLoading(true)
    if (delCard) {
      fetch('/removeCard?remove_pm=true').then((res) => {
        if (res.ok) {
          toast.success('Please allow upto 4 hours for your subscription to end. Your card has been removed.',{duration:7500});
          setConfirmRemoveModalLoading(false)
        } else {
          toast.error('Something went wrong, try again later',{duration:7500});
          setConfirmRemoveModalLoading(false)
        }
      })
    } else {
      fetch('/removeCard').then((res) => {
        if (res.ok) {
          toast.success('Please allow upto 4 hours for your subscription to end.',{duration:7500});
          setConfirmRemoveModalLoading(false)
        } else {
          toast.error('Something went wrong, try again later',{duration:7500});
          setConfirmRemoveModalLoading(false)
        }
      })
    }

  };
  useEffect(() => {
    if (props.getSubscription) {
      setPaymentInfo(props.getSubscription)
      setCurrentPlan(typesOfSubsBackwards[props.getSubscription['currentPlan']])
    }
  }, [props])
  const triggerChoosePlan = () => {
    setInitChangePlanModal(true)
  }
  return (
    <div className="container mt-5">
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center', marginBottom: "32px"
      }} className='billingHeader'>
        <h2>Billing and account</h2>
        <Form style={{ display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'flex-start' }}>
          <Col>
            {paymentInfo && paymentInfo.card && paymentInfo.card.length === 0 && (
              <Button variant="primary" onClick={triggerChoosePlan}>
                Choose Plan
              </Button>
            )}
            <Button variant="secondary" style={{ marginLeft: "8px" }} onClick={() => { setChangePasswordModal(true) }}>
              Change Password
            </Button>
            <Button variant="secondary" style={{ marginLeft: "8px" }} onClick={() => { setChangeEmailModal(true) }}>
              Change Email
            </Button>
          </Col>
        </Form>
      </div>

      {error && <Alert variant="danger">{error}</Alert>}

      {paymentInfo && paymentInfo.card.map((paymentMethod, i) => (
        <div key={i} className="mt-3 d-flex align-items-center billingRow" style={{
          borderBottom: '1px solid #d5d5d5',
          paddingBottom: '16px'
        }}>
          <Button style={{ display: 'flex', marginRight: '8px', backgroundColor: 'transparent', color: 'rgb(13, 110, 253)', alignItems: 'center' }}>
            <img src={getCardIcon(paymentMethod.brand)} style={{ marginRight: '8px', height: "20px" }} />Ending in {paymentMethod.last4}
          </Button>
          {paymentInfo && paymentInfo.card && paymentInfo.card.length !== 0 && (
            <Button variant="primary" onClick={handleChangePaymentMethod}>
              Change Payment Method
            </Button>
          )}
          {paymentInfo && paymentInfo.card && paymentInfo.card.length !== 0 && (
            <Button variant="secondary" style={{ marginLeft: "8px" }} onClick={() => { setChangePlanModal(true) }}>
              Change Plan
            </Button>
          )}

          <Button variant="danger" style={{ marginLeft: 'auto' }} onClick={() => handleRemovePaymentMethod(paymentMethod)}>
            Cancel
          </Button>
        </div>
      ))}
      {paymentInfo && paymentInfo.card && paymentInfo.card.length === 0 && (<div className="mt-3 d-flex align-items-center">No payment methods saved.</div>)}
      {props.getSubscription && (
        <Row className='justify-content-center'>
          <ProgressBar now={props.getSubscription.usage.count / props.getSubscription.usage.limit * 100} className={`mt-3 np custom-${getColorWarning(props.getSubscription.usage.count, props.getSubscription.usage.limit)}`} color='red' />
          <h6 className=' mt-3 ml-auto mr-auto text-center' style={{ fontSize: "14px" }}>{props.getSubscription.usage.count}/{props.getSubscription.usage.limit} queries, Until subscription renewal</h6>
        </Row>
      )}


      <Modal show={changeEmailModal} onHide={() => setChangeEmailModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Change Email</Modal.Title>
        </Modal.Header>
        <ChangeEmail setChangeEmailModal={setChangeEmailModal} />
      </Modal>
      <Modal show={changePasswordModal} onHide={() => setChangePasswordModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <ChangePassword setChangePasswordModal={setChangePasswordModal} />
      </Modal>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Payment Method</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {paymentInfo && paymentInfo.card && paymentInfo.card.length === 0 && (<CreditCardForm setShowModal={setShowModal} planChange={currnetPlan} />)}
          {paymentInfo && paymentInfo.card && paymentInfo.card.length !== 0 && (<CreditCardForm setShowModal={setShowModal} />)}
        </Modal.Body>
      </Modal>

      <Modal show={changePaymenthMethod} onHide={() => setChangePaymentMethod(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update payment method</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UpdateCreditCardForm setShowModal={setChangePaymentMethod}/>
        </Modal.Body>
      </Modal>

      <Modal show={changePlanModal} onHide={() => setChangePlanModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Change plan</Modal.Title>
        </Modal.Header>
        <ChangePlan setChangePlanModal={setChangePlanModal} currnetPlan={currnetPlan} setCurrentPlan={setCurrentPlan} paymentInfo={paymentInfo} newPlanRef={newPlanRef} setNewPriceModal={setNewPriceModal}/>
      </Modal>
      <Modal show={initChangePlanModal} onHide={() => setInitChangePlanModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Change plan</Modal.Title>
        </Modal.Header>
        <InitChangePlan setChangePlanModal={setInitChangePlanModal} currnetPlan={currnetPlan} setCurrentPlan={setCurrentPlan} openCard={setShowModal} />
      </Modal>
      <Modal show={confirmRemoveModal} onHide={() => setConfirmRemoveModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Cancel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to remove this subscription? You will be downgraded to "Free" tier.</p>
          <FormLabel style={{
            display: 'flex',
            gap: '12px',
            flexDirection: 'row',
            justifyContent: 'flex-start'
          }}>
            <FormCheck onClick={setDelCard} value={delCard} />
            Delete payment method
          </FormLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setConfirmRemoveModal(false)}>
            Go back
          </Button>
          <Button variant="danger" onClick={confirmRemove}>
            {!confirmRemoveModalLoading && "Cancel"}
            {confirmRemoveModalLoading && "Cancelling..."}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={newPriceModal} onHide={() => setNewPriceModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm change plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to change plans? <b>Your new price is: {typesOfSubsCost[typesOfSubsBackwards[newPlanRef.current.type]]}. { typesOfSubsCost[typesOfSubsBackwards[newPlanRef.current.type]] !== "Free" && `${typesOfSubsCost[typesOfSubsBackwards[newPlanRef.current.type]]} Will be charged.`}</b> If you are downgrading, instead you will get a service credit.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setNewPriceModal(false)}>
            Go back
          </Button>
          <Button variant="primary" onClick={handleSaveNewPlan}>
            {!newPriceLoading && "Change"}
            {newPriceLoading && "Changing..."}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};


const WrappedBillingSelfInfo = (props) => (
  <BillingSelfInfo {...props} />
);

export default WrappedBillingSelfInfo;
