import React, { createContext, useContext, useState } from 'react';

// Create a new context for user authentication
const AuthContext = createContext();
const SubscriptionData = createContext();
// Create a component to provide the AuthContext to its children
export const IsLoggedInProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(false); // State to track login status

  const login = () => {
    setLoggedIn(true);
  };

  const logout = () => {
    setLoggedIn(false);
  };

  return (
    <AuthContext.Provider value={{ loggedIn, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
export const useIsLoggedInProvider = () => {
    return useContext(AuthContext);
};

export const SubscriptionDataProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(false); // State to track login status
  return (
    <SubscriptionData.Provider value={{write:setLoggedIn,read:loggedIn}}>
      {children}
    </SubscriptionData.Provider>
  );
};
export const useSubscriptionDataProvider = () => {
    return useContext(SubscriptionData);
};