// SallyAITextClassificationAd.js

import React from 'react';
import { Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { tomorrowNight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
const SallyAITextClassificationAd = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-4">
          <Card>
            <Card.Body>
              <Card.Title>Emotion Tagging</Card.Title>
              <Card.Text>
                Identify emotions in text using advanced AI.
              </Card.Text>
              <Link to={'/shop#features'}>
              <Button>
                Learn More
              </Button>
              </Link>
            </Card.Body>
          </Card>
        </div>
        <div className="col-md-4">
          <Card>
            <Card.Body>
              <Card.Title>Keywords (for search)</Card.Title>
              <Card.Text>
                Automatically extract relevant keywords for better search.
              </Card.Text>
              <Link to={'/shop#features'}>
              <Button>
                Learn More
              </Button>
              </Link>
            </Card.Body>
          </Card>
        </div>
        <div className="col-md-4">
          <Card>
            <Card.Body>
              <Card.Title>Topics</Card.Title>
              <Card.Text>
                Classify text into relevant topics or categories.
              </Card.Text>
              <Link to={'/shop#features'}>
              <Button>
                Learn More
              </Button>
              </Link>
            </Card.Body>
          </Card>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-12">
          <h2>Test Sally          <Button variant="primary" className='mb-3 mt-3' onClick={handleDemoClick}>
            Run Demo
          </Button></h2>
          <div>
            <SyntaxHighlighter language='bash' style={tomorrowNight}>
                                {`> curl -X POST https://tca-api.sally-ai.com \\ \n-H "Content-Type: application/json" -d '{"text": "This is a sample text."}'`}
            </SyntaxHighlighter>
          </div>
          <div className="mt-3" id="demoResult">
            {/* Display demo API response here */}
          </div>
        </div>
      </div>
    </div>
  );
};

const handleDemoClick = () => {
  // Simulated API call (fake JSON response)
  const fakeApiResponse = {
      "data": {
        "baseTags": [
          "Hello world"
        ],
        "emtions": [
          {
            "label": "neutral",
            "score": 0.9646915793418884
          },
          {
            "label": "approval",
            "score": 0.012855284847319126
          }
        ],
        "keywords": "Hello world,  world. Hello world.Hello world",
        "topics": [
          {
            "label": "diaries_&_daily_life",
            "probability": 0.8012698888778687
          }
        ]
      },
      "error": null,
      "stauts": 200
  };

  // Display API response in the demoResult element
  const demoResultElement = document.getElementById("demoResult");
  demoResultElement.innerHTML = `<pre>${JSON.stringify(fakeApiResponse, null, 2)}</pre>`;
};

export default SallyAITextClassificationAd;
