import './index.css'

import React, { useState, useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import BillingSelfInfo from './BillingSelfInfo'; // Correct import path
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import QueryRunner from './queryRunner';
import MainDoc from './docs';
import { Accordion, Card } from 'react-bootstrap';
import { useIsLoggedInProvider, useSubscriptionDataProvider } from '../contexts';
import { useSearchParams } from 'react-router-dom';
const stripePromise = loadStripe('your_stripe_publishable_key');
const UserAccountPage = (props) => {
  const { loggedIn } = useIsLoggedInProvider()
  const { read } = useSubscriptionDataProvider()
  const [key, setKey] = useState('billing');
  const [getSubscription, setSubscription] = useState(false)
  const [urlParam,setUrlParam]= useSearchParams()
  useEffect(()=>{
    if (urlParam.get('tab')) {
      if (urlParam.get('tab') === "billing") {
        setKey("billing")
      }else if (urlParam.get('tab') === "webApi") {
        setKey("webapi")
      }else if (urlParam.get('tab') === "docs") {
        setKey("apiinfo")
      }else if (urlParam.get('tab') === "faq") {
        setKey("FAQ")
      }
    }
  },[urlParam])
  const faqItems = [
    {
      question: 'Why is the website so basic?',
      answer: 'This webpage is just a simple interface for the API. You can build your own app or website using the API.',
    },
    {
      question: 'What is the JSON schema for the API?',
      answer: 'The JSON schema is available in the API documentation. You can also use the Query Runner to see the JSON schema for each endpoint.',
    },
    {
      question: 'Why do the basetags contain software when the input has nothing to to do with software?',
      answer: 'The inputted text is likely too short and not enough to understand the message. This is common with texts under under 7 words. You can remove them by filtering them.',
    },
    {
      question: 'I need a refund or help',
      answer: 'You can send us an email at contact@nrrinc.net . Keep in mind, if you need help regarding the API, if you are not a enterprise user, your email will likely not be answered.',
    },
    {
      "question": "What is media tagging using text classification AI?",
      "answer": "Media tagging using text classification AI involves automatically assigning descriptive tags or labels to various forms of media content based on their textual descriptions or metadata."
    },

    {
      "question": "How does text classification AI assist in media tagging?",
      "answer": "Text classification AI analyzes the textual information associated with media files (such as captions, titles, or descriptions) to understand their content and context, enabling the automatic assignment of relevant tags or labels."
    },

    {
      "question": "What are some common applications of media tagging using text classification AI?",
      "answer": "1. Image Tagging: Automatically labeling images based on their content (e.g., 'beach', 'dog', 'cityscape').\n2. Video Categorization: Classifying videos into specific categories (e.g., 'sports', 'music', 'cooking').\n3. Audio Metadata: Tagging audio files with relevant keywords (e.g., 'podcast', 'interview', 'music genre')."
    }
    ,
    {
      "question": "How can text classification AI enhance content organization and retrieval?",
      "answer": "By accurately tagging media content, text classification AI improves the organization of digital libraries and enhances search and retrieval systems, enabling users to find relevant media more efficiently."
    },

    {
      "question": "What role does text classification AI play in recommendation systems?",
      "answer": "Text classification AI powers recommendation engines by suggesting related media content based on tagged similarities, leading to more personalized and engaging content experiences for users."
    },

    {
      "question": "How does media tagging using text classification AI benefit content moderation?",
      "answer": "Media tagging AI assists in content moderation by automatically identifying and flagging inappropriate or sensitive content based on predefined tags, ensuring a safer and more secure online environment."
    },

    {
      "question": "What challenges are associated with media tagging using text classification AI?",
      "answer": "Challenges include handling ambiguous descriptions, adapting to diverse content types and languages, ensuring consistent tag assignments, and mitigating biases in the tagging process."
    },

    {
      "question": "Can text classification AI be applied to real-time media tagging?",
      "answer": "Yes, text classification AI can be deployed in real-time systems to automatically tag newly uploaded media content, providing immediate categorization and organization capabilities."
    },

    {
      "question": "How can organizations leverage media tagging using text classification AI for business insights?",
      "answer": "By analyzing tagged media data, organizations can derive valuable insights into user preferences, content trends, and audience engagement patterns, informing strategic decisions and content strategies."
    }

    // Add more FAQ items here...
  ];
  useEffect(() => {
    if (read) {
      setSubscription(read)
    }
  }, [read])
  return (
    <div className="container mt-3">
      <Tabs
        id="user-account-tabs"
        activeKey={key}
        onSelect={(k) => {setKey(k);
        if (k === "billing") {
          setUrlParam({"tab":"billing"})
        }else if (k === "webapi") {
          setUrlParam({"tab":"webApi"})
        }else if (k === "apiinfo") {
          setUrlParam({"tab":"docs"})
        } else if (k === "FAQ") {
          setUrlParam({"tab":"faq"})
        } 
        }}
        className="mb-3"
      >
        <Tab eventKey="billing" title="Billing/Self Info">
          {loggedIn ? (
            <Elements stripe={stripePromise}>
              <BillingSelfInfo getSubscription={getSubscription} setSubscription={setSubscription} />
            </Elements>
          ) : (
            <div><h2>You must log in to view this page.</h2></div>
          )}
        </Tab>
        <Tab eventKey="webapi" title="Web API">
          {/* Web API Content */}
          {loggedIn ? (
            <div>
              <h2>Web API</h2>
              <QueryRunner getSubscription={getSubscription} setSubscription={setSubscription} />
            </div>
          ) : (
            <div><h2>You must log in to view this page.</h2></div>
          )}

          {/* Add your Web API settings or content here */}
        </Tab>
        <Tab eventKey="apiinfo" title="API Docs">
          {/* API Info/Docs Content */}
          <h3 style={{ textAlign: 'center' }}>API Docs</h3>
          <MainDoc getSubscription={getSubscription} setSubscription={setSubscription} />
          {/* Add your API info or documentation here */}
        </Tab>
        <Tab eventKey={"FAQ"} title="Faq">
          <Accordion>
            {faqItems.map((item, index) => {
              return (
                <Card key={index}>
                  <Accordion.Item eventKey={index.toString()}>
                    <Accordion.Header>{item.question}</Accordion.Header>
                    <Accordion.Body>
                      {item.answer}
                    </Accordion.Body>
                  </Accordion.Item>
                </Card>
              )
            })}
          </Accordion>
        </Tab>
      </Tabs>
    </div>
  );
};

export default UserAccountPage;