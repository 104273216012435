export default function D() {
    return (

        <div className="embed-preview no-select">
            <p>Our policy is valid for a period of 3 calendar days from the date of the purchase. If you are not satisfied with the service, you can ask for a refund. A pro-rated refund will be issued for the unused portion of the services. If the period of 3 days has lapsed since the purchase, we can’t, unfortunately, offer you a refund.</p>
            <h2>Refund requirements</h2>
            <p>The following criteria must be met to qualify for a refund:</p>
            <ul>
                <li>Customer’s account must be in good standing</li>
                <li>Service must not be used</li>
                <li>Service malfunctions or doesn’t work as described</li>
            </ul>
            <p>If the conditions listed above are not met, we reserve the right not to issue a refund. It’s important to keep in mind that there is often a difference between a service that doesn’t work and a situation where you are receiving an error message. Error messages could be related to an incorrect setup, configuration or software and as a result the service is not working.</p>
            <h2>Inquire about a refund</h2>
            <p>Send an email to <a href="mailto:contact@nrrinc.net">contact@nrrinc.net</a>.</p>
            <h2>Contacting us</h2>
            <p>If you have any questions, concerns, or complaints regarding this refund policy, we encourage you to contact us using the details below:</p>
            <p><a href="mailto:legal@nrrinc.net">legal@nrrinc.net</a></p>
            <p>This document was last updated on April 22, 2024
            </p><p></p> </div>
    )
}