import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider} from 'react-router-dom';
import { StorePage } from './componet/store';
import UserAccountPage from './componet/account';
import SigninSignup from './componet/account/login';
import SallyAITextClassificationCard from './componet/home';
import { IsLoggedInProvider, SubscriptionDataProvider } from './componet/contexts';
import PolicyTabs from './componet/terms'
const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: "/",
        element: <SallyAITextClassificationCard />
      },
      {
        path: "/shop",
        element: <StorePage />
      },
      {
        path: "/account",
        element: <UserAccountPage />
      },
      {
        path: "/login",
        element: <SigninSignup />
      },
      {
        path:"/legal",
        element:<PolicyTabs/>
      }
    ]
  }
])
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <IsLoggedInProvider>
      <SubscriptionDataProvider>
        <RouterProvider router={router} />
      </SubscriptionDataProvider>
    </IsLoggedInProvider>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
